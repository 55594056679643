@keyframes slide {
    0% { transform: translateX(0%); }
    25% { transform: translateX(-10%); }
    50% { transform: translateX(0%); }
    75% { transform: translateX(-10%); }
    100% { transform: translateX(0%); }
  }
  
  .animate-slide {
    animation: slide 1s infinite;
  }