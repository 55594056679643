.mobile_menu {
    position: fixed;
    top: -1500px;
    left: 0;
    height: 100%;
    width: 100%;
    transition: top 0.3s ease-in-out;
}

.mobileMenu_active {
    z-index: 1000;
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: #DCCFED;
    transition: top 0.3s ease-in-out;
}